<template>
	<div v-if="!auth && ll" class="blocklogin">
		<h2>{{ll.i6764}}</h2>
		<div class="inputuser">
			<i class="fas fa-envelope"></i>
			<input :class="[loginTrigger && error.email?'redb':'']" type="text" :placeholder="ll.i6765" v-model="user.email" />
			<div v-if="error.email" class="redError">{{error.email}}</div>
		</div>
		<div class="inputuser">
			<i class="loginmail"></i>
			<input :class="[loginTrigger && error.password?'redb':'']" type="password" :placeholder="ll.i6766" v-model="user.password" />
			<div v-if="error.password" class="redError">{{error.password}}</div>
		</div>
		<div v-if="register" class="inputuser">
			<i class="fas fa-user"></i>
			<input :class="[loginTrigger && error.username?'redb':'']" type="text" :placeholder="ll.i6767"  v-model="user.username" />
			<div v-if="error.username" class="redError">{{error.username}}</div>
		</div>
		<div v-if="false" class="restore" data-ll="6771">{{ll.i6771}}</div>

		<div class="buttonlogin" @click="login" data-ll="6768">{{ll.i6768}}</div>
		<div class="text"><i class="fas fa-check"></i> <span data-ll="6769" v-html="ll.i6769"></span></div>

		<div class="button-social">
			<span class="orconnect" data-ll="6770">{{ll.i6770}}</span>
			<span id="authFb" class="authSoc" @click="openFacebook"><i class="fab fa-facebook-square"></i></span>
			<span id="authGl" class="authSoc" @click="openGoogle"><i class="google-icon"></i></span>
			<span id="authMs" class="authSoc" @click="openMicrosoft"><i class="fab fa-windows"></i></span>
		</div>
	</div>
</template>
<script>
	/* eslint-disable */
	import axios from 'axios'
	import SNotification from "../components/SNotification";

	export default {
		name: 'BlockLogin',
		data: function() {
			return  {
				ll: null,
				auth: false,
				loginTrigger: false,
				register: false,
				user: {
                    email:null,
					password:null,
					usernasme:null
				},
				error:{
                    email:null,
                    password:null,
                    usernasme:null
				}

			}
		},
		components: {
			SNotification
		},
		created() {
			document.addEventListener('keypress', (ev) => {
				if(ev.key=='Enter') this.login();
			});
		},
		watch:{
			lang(to, from){
				this.getll();
			}
		},
		methods: {
			async init(){
				await this.getll();
				await this.main();

			},
			async getll(){
				let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["blocklogin"]}, controller:[{name:"auth"}] });
				if(res && res.data && res.data.datall) {
					if(res.data.datall) this.ll = res.data.datall;
                    if(res.data.auth) this.auth = true;
				}
			},
			async main(){
				//let res = await axios.post('/api/main/get', {controller:[{name:"wechat"}, {name:"manager"}] });
				//if(res && res.data ) {
				//}
			},
            async authf(){
                let res = await axios.post('/api/main/get', {controller:[{name:"auth"}] });
                if(res && res.data && res.data.auth) {
                    this.auth = true;
                }
			},
            async login(){
				this.loginTrigger = true;
				let valid = true;
				if(!this.user.email){
                    valid = false;
                    this.error.email = this.ll.i6773 || 'Email is empty';
				}
				else if(!this.validateEmail(this.user.email)){
                    valid = false;
                    this.error.email = this.ll.i6774 ||'Email is not valid';
				}
				else{
                    this.error.email = null;
				}

                if(!this.user.password){
                    valid = false;
                    this.error.password = this.ll.i6775 ||'Password is empty';
                }
                else if(!this.validatePassword(this.user.password)){
                    valid = false;
                    this.error.password = this.ll.i6776 ||'Password is not valid';
                }
                else{
                    this.error.password = null;
                }
				if(!valid) return;

                let res = await axios.post('/api/user/login', {email:this.user.email, pass:this.user.password });
                if(res && res.data) {
                    if(res.data.result){
                        this.auth = true;
						location.reload();
						if(typeof this.$parent.auth !=='undefined') this.$parent.auth = true;
					}
					else{
                        this.error.email = this.ll.i6777;
						this.error.password = " ";
					}
                }
			},
            openFacebook(){
                let path = 'https://semalt.info/facebook_login.php';
                let width = 600;
                let height = 400;
                let l = window.screenX + (window.outerWidth - width) / 2;
                let t = window.screenY + (window.outerHeight - height) / 2;
                let winProps = ['width='+width,'height='+height,'left='+l,'top='+t,'status=no','resizable=yes','toolbar=no','menubar=no','scrollbars=yes'].join(',');
                window.open(path, 'Facebook', winProps);
			},
            openGoogle(){
                let path = 'https://semalt.com/google_login.php';
                location.href = path;
            },
            openMicrosoft(){
                let path = 'https://semalt.com/microsoft/microsoft_login.php';
                location.href = path;
            },
            validateEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            validatePassword(pass){
				if(pass.length>5) return true;
				return false;
			}
		},
		computed:{
			lang(){
				return this.$root.lang;
			}
		},
		mounted(){
			this.init();
		}
	}
</script>
<style scoped lang="less">
	@font-face {
		font-family: "Segoe UI";
		src: url(/css/fonts/segoeui.ttf);
		font-weight: normal;
		font-style: normal;
	}
	.blocklogin{
		width: 340px;
		margin: 0 auto;
		padding: 40px;

		.inputuser{
			width: 340px;
			height: 64px;
			font-family: "Segoe UI";
			font-size: 16px;
		}
		.inputuser input{
			width: 100%;
			height: 40px;
			border: 1px solid #BBBBBB;
			border-radius: 4px;
			padding: 0 12px 0 50px;
			font-family: "Segoe UI";
			font-size: 16px;
			box-sizing: border-box;
		}
		.inputuser input.redb{
			border: 1px solid #e4486b;
		}
		.inputuser .redError{
			color: #e4486b;
			text-align: left;
			font-size: 12px;
		}
		.fa-envelope{
			position: absolute;
			margin: 11px 0 0 16px;
			display: block;
			font-size: 20px;
		}
		.fa-user{
			position: absolute;
			margin: 10px 0 0 18px;
			display: block;
			font-size: 20px;
		}
		.loginmail{
			position: absolute;
			margin: 8px 0 0 17px;
			width: 18px;
			height: 24px;
			display: block;
			background: url(/img/icons/lock.png) no-repeat;
			background-size: 18px 24px;
		}
		.buttonlogin{
			margin-top: 15px;
			min-width: 300px;
			height: 50px;
			display: inline-block;
			border-radius: 5px;
			background-color: #2ECC71;
			color: #fff;
			line-height: 50px;
			font-family: "Ebrima";
			font-weight: bold;
			font-size: 16px;
			text-align: center;
			cursor: pointer;
			padding: 0 20px;
		}
		.buttonlogin:hover{
			opacity: 0.6;
		}
		.text{
			margin-top: 20px;
			color: #BBBBBB;
			text-decoration: none;
			font-family: "Segoe UI";
		}

		.restore{
			text-align: left;
			color: #63a5cf;
			cursor: pointer;
		}
		.orconnect{
			color: #4C4C4C;
			position: relative;
			top: -8px;
			margin-right: 8px;

		}
		.authSoc{
			margin: 20px 0 0 16px;
			width: 32px;
			height: 32px;
			display: inline-block;
			cursor: pointer;
		}
		.fa-facebook-square{
			color: #3C5A99;
			font-size: 32px;
		}
		.google-icon{
			width: 28px;
			height: 29px;
			display: inline-block;
			background: url(/img/icons/icon-google.png) no-repeat;
			background-size: 28px 29px;
			position: relative;
			top: 3px;
		}
		.fa-windows{
			color: #00ACEE;
			font-size: 32px;
		}
		.fa-check{
			color: #2ECC71;
			font-size: 16px;
		}

	}
	@media(max-width: 640px){
		.blocklogin{
			width: 100%;
			padding: 30px;
			box-sizing: border-box;

			.inputuser{
				width: 100%;
			}
			.buttonlogin{
				min-width: 100%;
				box-sizing: border-box;
			}
		}
	}
</style>
<style lang="less">
	a.underline{
		color: #00AAF2;
		text-decoration: none;
	}
</style>