<template>
	<div class="blockvideo blockvideotexts">
		<div class="blockvideo2">
			<table>
				<tr><td data-ll="6632">{{ll.i6632}}</td>
					<td class="tbold">{{row.name}}</td></tr>
				<tr><td data-ll="6633">{{ll.i6633}}</td>
					<td class="tbold">{{row.work}}
						<template v-if="row.link_website">
							<a :href="row.link_website" target="_blank">{{row.company_name}}</a>
						</template>
						<template v-else>
							{{row.company_name}}
						</template>
					</td></tr>
				<tr><td data-ll="6634">{{ll.i6634}}</td>
					<td class="tbold">{{row.company_desc}}</td></tr>
				<tr><td data-ll="6635">{{ll.i6635}}</td>
					<td class="tbold"><span class="flag3"><img :src="'/img/flags2/'+row.flag+'.png'" /></span>{{row.country}}</td></tr>
			</table>
			<div class="tvideo tvideobg">
				<span class="quote"></span>
				<span>{{row.text}}</span>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "CaseText",
		props:['ll','row']
	}
</script>
<style scoped lang="less">
.blockvideo{
	margin: 32px 6px 0 6px;
	width: 100%;
	max-width: 396px;
	box-shadow: 0 3px 10px rgba(0,0,0,0.15);
	border-radius: 5px;
	text-align: left;
	float: left;
	min-height: 458px;
	font-family: "Segoe UI";
	font-weight: 400;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.blockvideo:hover{
	box-shadow: 0 3px 11px rgba(0,0,0,0.45);
}
.blockvideo2{
	padding: 0 24px 22px;
}

.blockvideo table{
	text-align: left;
}
.blockvideo table tr td{
	padding-bottom: 15px;
	min-height: 24px;
	line-height: 24px;
	font-weight: normal;
	font-size: 15px;
	color: #B1B1B1;
	padding-right: 12px;
	vertical-align: top;
	min-width: 82px;
}
.blockvideo.blockvideotexts table tr td{
	margin-top: -3px;
	line-height: 22px;
}
.blockvideo table tr td.tbold{
	font-size: 17px;
	color: #4C4C4C;
	padding-right: 0;
	font-weight: 700;
}
.blockvideo.blockvideotexts table tr td.tbold{
	font-size: 16px;
	font-weight: 700;
}
.blockvideo table tr td.h50{
	min-height: 24px;
}
.blockvideo table tr td.tbold a{
	color: #00aaf2;
	text-decoration: none;
}
.blockvideo .flag3{
	position: relative;
	top: 3px;
	padding-right: 6px;
	display: inline-block;
}
.blockvideo .tvideo {
	width: 350px;
	height: 205px;
	border-radius: 5px;
	position: relative;
	text-align: center;
	margin-top: 2px;
}
.blockvideo .tvideo.tvideobg {
	background-color: #F6F6F6;
	padding: 16px 28px 20px 32px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 14px;
	color: #4C4C4C;
	text-align: justify;
	overflow-y: scroll;
}
.blockvideo .quote{
	display: inline-block;
	position: relative;
	top: 0px;
	width: 38px;
	height: 28px;
	background-image: url(/img/testimonials/2019/format_quote-1.png);
	background-repeat: no-repeat;
	background-position: -5px 2px;
}
@media(max-width: 640px){
	.blockvideo{
		width: 90%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin: 15px 5%;
	}
	.blockvideo2 {
		padding: 0 15px 15px;
	}
	.blockvideo .tvideo{
		width: 100%;
	}
}
</style>
