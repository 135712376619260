<template>
	<div class="writereview">
		<s-notification ref="notifywritereview"></s-notification>
		<div v-if="ll" class="wcontent">
			<div class="center">
				<h2 class="wtitle" data-ll="6650">{{ll.i6650}}</h2>
				<div class="smline"></div>
			</div>

			<BlockLogin v-if="!auth"></BlockLogin>

			<div v-if="auth" class="blockedit">

				<table class="blocktable">
					<tr>
						<td class="qtitle qtitleone" colspan="2">
							<div class="tname" data-ll="6721">{{ll.i6721}}</div>
							<div class="tline"></div>
						</td>
					</tr>
					<tr>
						<td data-ll="6722">{{ll.i6722}}</td>
						<td><input type="text" :class="[savetrigger&&!datacase.name?'redinput':'', 'winput']" data-ll-placeholder="6723" :placeholder="ll.i6723" v-model="datacase.name" /></td>
					</tr>
					<tr>
						<td data-ll="6724">{{ll.i6724}}</td>
						<td><input type="text" :class="[savetrigger&&!datacase.work?'redinput':'', 'winput']" data-ll-placeholder="6725" :placeholder="ll.i6725" v-model="datacase.work" />
							<div class="example eleft" data-ll="6726">{{ll.i6726}}</div>
						</td>
					</tr>
					<tr>
						<td data-ll="6727">{{ll.i6727}}</td>
						<td>
							<div class="selectCountry">
								<sselect v-if="arrCountry" :imgInput="1" @change="changeCountryCases" v-bind:post="arrCountry"  v-model="keyCountryCases"></sselect>
							</div>
						</td>
					</tr>
					<tr>
						<td class="qtitle" colspan="2">
							<div class="tname" data-ll="6729">{{ll.i6729}}</div>
							<div class="tline"></div>
						</td>
					</tr>
					<tr>
						<td data-ll="6730">{{ll.i6730}}</td>
						<td><input type="text" :class="['winput']" data-ll-placeholder="6732" :placeholder="ll.i6732" v-model="datacase.companyName" />
							<div class="example eleft" data-ll="6731">{{ll.i6731}}</div>
							<!--savetrigger&&!datacase.companyName?'redinput':'', savetrigger&&!datacase.linkWebsite?'redinput':''-->
						</td>
					</tr>
					<tr>
						<td data-ll="6733">{{ll.i6733}}</td>
						<td><input type="text" :class="['winput']" data-ll-placeholder="6734" :placeholder="ll.i6734" v-model="datacase.linkWebsite" />
							<div class="example eleft" data-ll="6731">{{ll.i6731}}</div>
						</td>
					</tr>
					<tr>
						<td data-ll="6735" class="wtop">{{ll.i6735}}</td>
						<td>
							<div class="example eright" data-ll="6737">{{ll.i6737}}</div>
							<textarea :class="[savetrigger&&!datacase.companyDesc?'redinput':'', 'winput']" class="warea" data-ll-placeholder="6736" :placeholder="ll.i6736" v-model="datacase.companyDesc"></textarea>
							<div class="example eleft" data-ll="6738">{{ll.i6738}}</div>
						</td>
					</tr>
					<tr>
						<td class="qtitle" colspan="2">
							<div class="tname" data-ll="6739">{{ll.i6739}}</div>
							<div class="tline"></div>
						</td>
					</tr>
				</table>
				<div>
					<textarea :class="[savetrigger&&!datacase.text?'redinput':'', 'winput']" class="wareabig" data-ll-placeholder="6740" :placeholder="ll.i6740" v-model="datacase.text"></textarea>
				</div>
				<div class="center">
					<div class="wbutton" @click="saveCase" data-ll="6741">{{ll.i6741}}</div>
				</div>

			</div>
		</div>


		<div v-if="ll">
			<div class="block3">
				<div class="tnavigate" id="WrittenTestimonials"></div>
				<h2 class="h2" data-ll="6637">{{ll.i6637}}</h2>
				<div class="filters">
					<span class="namefilter" data-ll="6630">{{ll.i6630}}</span>

					<sselect @change="changeFilterCasesText" v-if="ll.i6631" :placeholder="ll.i6631" v-bind:post="filterCasesText"  v-model="keyFilterCasesText"></sselect>
				</div>
			</div>

			<div class="block2">
				<div class="videoblocks2">
					<template v-if="arrCasesText.length>hooperSettings.itemsToShow">
						<hooper v-if="hooperStart" ref="carousel" :settings="hooperSettings" @beforeSlide="beforeSlide" @afterSlide="afterSlide" @slide="updateCarousel">
							<slide v-for="(row, index) in arrCasesText" :data-id="row.id" :key="index" :index="index" >
								<case-text v-bind:ll="ll" v-bind:row="row"></case-text>
							</slide>
							<hooper-navigation slot="hooper-addons"></hooper-navigation>
						</hooper>
					</template>
					<template v-else>
						<div v-for="(row, index) in arrCasesText" :data-id="row.id" :key="index" :index="index" >
							<case-text v-bind:ll="ll" v-bind:row="row"></case-text>
						</div>
					</template>
				</div>
				<div class="clear"></div>
			</div>
		</div>


		<div class="blocksupport">
			<BlockRequest></BlockRequest>
		</div>
	</div>
</template>
<script>
	/* eslint-disable */
	import axios from 'axios'
	import BlockRequest from '../components/BlockRequest';
	import BlockLogin from '../components/BlockLogin';
	import Sselect from "../components/Sselect";
	import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
	import 'hooper/dist/hooper.css';
	import SNotification from "../components/SNotification";
	import CaseText from "../components/CaseText";
	/*Vue.component('button-counter', {
		data: function () {
			return {
				count: 0
			}
		},
		template: '<button v-on:click="count++">Счётчик кликов — {{ count }}</button>'
	});*/
	export default {
		name: 'WriteReview',
		data: function() {
			return  {
				msg: "Wechat",
				ll: null,
				qrlink:null,
				manager:null,
				filterCasesText: [ {id:0,val:"All", country:"int"} ],
				keyFilterCasesText:0,
				arrCasesText: [],
				loadCases: 0,
				firstLoad: false,
				totalCases: 0,
				arrCasesParams: [0, 6, 0],
				hooperSettings: {
					itemsToShow: 3,
					itemsToSlide:3,
					initialSlide: 0,
					infiniteScroll: true,
					autoPlay:false,
					playSpeed: 4000,
					mouseDrag: false,
					player: []
				},
				hooperStart: false,
				arrCountry: null,
				keyCountryCases: { id:"gb", country:"gb", val:"United Kingdom"},
				datacase:{
					name:null,
					work:null,
					country:null,
					flag:null,
					companyName:null,
					linkWebsite:null,
					companyDesc:null,
					text:null,
				},
				savetrigger: false,
				auth: false
			}
		},
		components: {
			CaseText,
			Sselect,
			BlockRequest,
			Hooper,
			Slide,
			HooperNavigation,
			SNotification,
			BlockLogin
		},
		created() {
/*			document.addEventListener('resize', (ev) => {
				console.log(123);
			});*/
		},
		watch:{
			lang(to, from){
				this.getll();
			}
		},
		methods: {
			async init(){
				this.route();
				this.authf();
				await this.getll();
				await this.main();

				if(window.outerWidth<1260) this.hooperSettings.itemsToShow = 1;
				this.hooperStart = true;

				this.getCasesText(0,0,0,9);
				let getFilterCasesText = await this.getCases('getFilterCasesText');
				this.filterCasesText = this.filterCasesText.concat(getFilterCasesText);
				let res = await this.getCases('getCountry', {default:true});
				if(res.result){
					this.arrCountry = res.result;
					if(res.default) this.keyCountryCases = res.default;
				}
			},
			async getll(){
				let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["client-testimonials"]} });
				if(res && res.data && res.data.datall) {
					this.ll = res.data.datall;
				}
			},
			async main(){
				let res = await axios.post('/api/main/get', {controller:[{name:"wechat"}, {name:"manager"}] });
				if(res && res.data ) {
					if(res.data.wechat && res.data.wechat.qr){
						this.qrlink = res.data.wechat.qr;
					}
					if(res.data.manager){
						this.manager = res.data.manager;
					}
				}
			},
			route(){
				if(this.$route.params.lang){
					if(this.$root.languages.includes(this.$route.params.lang)){
						if(this.$route.params.lang != this.$root.lang) {
							this.$root.lang = this.$route.params.lang;
						}
					}
					else{
						this.$router.push('/en/wechat');
						this.$router.push('/wechat');
						this.$root.lang = 'en';
					}
				}
			},
			changeCountryCases(item){
				//console.log(item, this.keyCountryCases);
			},
			async getCasesText(val, refresh, start, limit){
				if(this.loadCases >= this.totalCases && this.loadCases>0){
					console.log('loadCases>=totalCases');
					return;
				}
				val = val || 0;
				refresh = refresh || 0;
				start = start || 0;
				limit = limit || 3;

				let res = await this.getCases('getCasesTextNew', {val:val, start: start, limit:limit });
				if(refresh==1){
					this.arrCasesText = [];
				}
				if(res.result && res.total){
					this.arrCasesText = this.arrCasesText.concat( res.result );

					if(this.$refs.carousel) this.$refs.carousel.restart();
					this.totalCases = res.total;

					if(refresh==1) this.loadCases = res.result.length;
					else this.loadCases += res.result.length;

					if(!this.firstLoad){
						setTimeout(()=>{this.firstLoad=true;},2000);
					}
				}
			},
			changeFilterCasesText(){
				this.loadCases = 0;
				this.getCasesText(this.keyFilterCasesText.id, 1, 0, 200);
			},
			async getCases(method, data){
				let res = await axios.post('/api/cases/'+method, data);
				if(res && res.data) return res.data;
				return null;
			},
			async updateCarousel(payload){
				//Hooper.update()
				//console.log('!!!',this, payload, this.loadCases);
			},
			async beforeSlide(payload){
				if(this.firstLoad) {
					await this.getCasesText(this.keyFilterCasesText.id, 0, 9, 200);
				}
				//payload.currentSlide
			},
			async afterSlide(payload){
				//payload.currentSlide

			},
            async authf(){
                let res = await axios.post('/api/main/get', {controller:[{name:"auth"}] });
                if(res && res.data && res.data.auth) {
                    this.auth = true;
                }
            },
			async saveCase(){
				this.datacase.country = this.keyCountryCases.val;
				this.datacase.flag = this.keyCountryCases.country.toUpperCase();
				this.savetrigger = true;


				let datacase ={
					name:null,
					work:null,
					country:null,
					flag:null,
					companyName:null,
					linkWebsite:null,
					companyDesc:null,
					text:null,
				}
				if(!this.datacase.name || !this.datacase.work || !this.datacase.companyDesc || !this.datacase.text ){
					return; //!this.datacase.companyName || !this.datacase.linkWebsite ||
				}
				let res = await this.getCases('saveCasesText', this.datacase);
				if(res && res.result){
					this.savetrigger = false;
					this.datacase.name = null;
					this.datacase.work = null;
					this.datacase.companyName = null;
					this.datacase.linkWebsite = null;
					this.datacase.companyDesc = null;
					this.datacase.text = null;

					this.$refs.notifywritereview.text(this.ll.i6742 || "Your case has been sent successfully",3000);
				}
			},
			setResize(){
				console.log('resize');
				this.hooperStart = false;
				if(window.outerWidth<1260) this.hooperSettings.itemsToShow = 1;
				else this.hooperSettings.itemsToShow = 3;
				this.hooperStart = true;
				this.$refs.carousel.restart();
			}
		},
		computed:{
			lang(){
				return this.$root.lang;
			}
		},
		mounted(){
			this.init();
			this.$nextTick(function() {
				window.addEventListener('resize', this.setResize);
			})
		}
	}
</script>
<style src="../assets/less/writereview.less" lang="less"></style>



